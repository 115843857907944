import React, { useEffect, useState } from 'react'
import '../../components/DashboardHeader/DashboardHeader.css'
import bellIcon from '../../assets/bellIcon.svg'
import profileIcon from '../../assets/profileIcon.svg'
import downArrow from '../../assets/downArrow.svg'
import feedbackIcon from '../../assets/feedbackIcon.svg'
import logoutBtn from '../../assets/LogoutBtn.svg'
import { useNavigate } from 'react-router-dom'
import Modal from "react-modal";
import Lottie from "lottie-react";
import LogOutLogo from '../../assets/logOutLogo.svg'
import { PortalUserRole, Url } from '../../EnvoirnmentSetup/APIs'
import completed from '../../assets/Success.json'
import Oops from "../../assets/Opps.json";
import userProIcon from '../../assets/userProIcon.svg'
import logOutLogo from '../../assets/logoutBtnNew.svg'
import bellIconWithoutDot from '../../assets/bellIconWithoutDot.svg'
import { useAuthContext, useNotificationContext, useProfileDropdownContext, useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from '../../Context/Context'
import helpIcon from '../../assets/Help and Report.svg'
import { RxHamburgerMenu } from "react-icons/rx";
import QuikHireLogo from '../../assets/quikHireLogo.png'
import { ModuleCards } from '../../constants/ModuleCards'
import dashIconWhite from '../../assets/dashIconWhite.svg'
import sidebarLogo2 from '../../assets/sidebarLogo2.svg'
import sidebarLogo3 from '../../assets/sidebarLogo3.svg'
import jobRepoGray from '../../assets/jobRepoGray.svg'
import UserIconGrey from '../../assets/UserIconGrey.svg'
import dashIconGrey from '../../assets/dashIconGrey.svg'
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import managementIconGray from '../../assets/managementIconGray.svg'
import managementIconWhite from '../../assets/managementIconWhite.svg'
import createJob from '../../assets/Qcreate a job.svg'
import reportsGray from '../../assets/reportsGray.svg'
import reportsWhite from '../../assets/ReportsWhite.svg'
import { getPremiumMemberUserInfo } from '../../constants/constantFunctions'
import myJobsWhite from '../../assets/myJobWhiteNew.svg'
import myJobsGray from '../../assets/myJobGrayNew.svg'
import companyJobWhite from '../../assets/companyJobsWhite.svg'
import companyJobGray from '../../assets/companyJobsGray.svg'
import teamManagementGray from '../../assets/teamManagementGray.svg'
import teamManagementWhite from '../../assets/teamManagementWhite.svg'
import companyMngGray from '../../assets/companyMngGray.svg'
import companyMngWhite from '../../assets/companyMngWhite.svg'
import qIcon from '../../assets/qIcon.svg'


const DashboardHeader = (props) => {
  console.log("propsData", props);
  const navigate = useNavigate()

  const { notificationCount } = useNotificationContext()
  const [errPopup, setErrPopup] = useState(false)
  const [lgOutPopup, setLgOutPopup] = useState(false)
  const [fdbckPopup, setFdbckPopup] = useState(false)
  const [successPopup, setSuccessPopup] = useState(false)
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [popupMsg, setPopupMsg] = useState("")
  const [fdbck, setFdbck] = useState("")
  const [newNotification, setNewNotification] = useState(0)
  const [openSidebar, setOpenSidebar] = useState(false)
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const { dropDownVisible, setDropDownVisible } = useProfileDropdownContext()
  const { subMenu, setSubMenu, subMenuColor, setSubMenuColor } = useSelectedSubMenuContext();
  const { subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs } = useSelectedSubMenuJobContext();
  const { userDetails, setUserDetails } = useAuthContext();

  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/login')
  }

  useEffect(() => {
    try {
      fetch(Url.NotificationsCount, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': "Bearer " + sessionStorage.getItem("token"),
        }
      }).then((resp) => resp.json())
        .then((res) => {
          console.log("notifications count response", res);
          setNewNotification(res?.DATA)
        })
        .catch((e) => {
          console.log(e);
        })
    }
    catch (e) {
      console.log(e);
    }
  }, [notificationCount]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60%",
      maxWidth: "88%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const sidebarStyle = {
    content: {
      position: "absolute",
      top: "0%",
      left: "0%",
      width: "70%",
      border: "none",
      height: "100%",
      borderRadius: "none",
      padding: "10px 0"
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const handleInputChange = (e) => {
    setFdbck(e?.target?.value)
  }

  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  const handleFdbckSend = () => {
    let msg = {
      feedback: fdbck,
    }
    let token = sessionStorage.getItem("token");
    if (!(!!fdbck?.trim())) {
      setError("Feedback cannot be empty.");
      setFdbckPopup(false)
      openFailModal();
    }
    else if (fdbck?.trim()?.length < 15) {
      setError("Feedback should contain atleast 15 characters.");
      setFdbckPopup(false)
      openFailModal();
    }
    else {
      try {
        fetch(Url.SendFeedBack, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(msg)
        }).then((resp) => resp.json())
          .then((res) => {
            console.log("response feedback api", res);
            if (res?.SUCCESS == true && res?.DATA == true) {
              setSuccessPopup(true);
              setPopupMsg("Feedback sent successfully.")
              setFdbck("")
            } else {
              setError(res?.message);
              setFdbckPopup(false)
              openFailModal();
            }
          })
          .catch((e) => {
            console.log(e);
          })
      }
      catch (e) {
        console.log(e);
      }
    }
  }
  let userName = JSON.parse(sessionStorage.getItem('userName'))

  const clearSession = () => {
    sessionStorage.removeItem("TablePageNumber");
    sessionStorage.removeItem("storedPageNumber");
    sessionStorage.removeItem("filterOption");
    sessionStorage.removeItem("pageEntriesLength");
    sessionStorage.removeItem("fromDate");
    sessionStorage.removeItem("toDate");
    sessionStorage.removeItem("DateSubmitStatus");
    sessionStorage.removeItem("searchTextString");
  };

  const handleProfile = () => {
    setDropDownVisible(false)
    clearSession();
    navigate('/dashboard/profile')
  }

  const handleNotifications = () => {
    setSelectedCard(ModuleCards?.Notification)
    setDropDownVisible(false)
    setSubMenu(false)
    setSubMenuJob(false)
    clearSession();
    navigate('/dashboard/notification')
  }

  const handleMouseLeave = () => {
    setTimeout(() => {
      setDropDownVisible(false)
    }, 1000)
  }

  const handleCreateJob = () => {
    if (PortalUserRole == "ROLE_RECRUTER" && !userDetails?.isActive) {
      setPopupMsg("Please contact your manager to fully activate your account.")
      setErrPopup(true)
    }
    else if (userDetails?.jobLicense?.remainingJobPost != 0) {
      setDropDownVisible(false)
      setOpenSidebar(!openSidebar)
      getPremiumMemberUserInfo().then((status) => {
        if (status) {
          setSelectedCard(2);
          navigate('/dashboard/createJobs'); setDropDownVisible(false)
        }
      })
    }
    else {
      if (PortalUserRole == "ROLE_MANAGER") {
        setPopupMsg("No more licenses available. Purchase additional licenses to create job posts.")
      }
      else {
        setPopupMsg("No more licenses available. Please contact your manager for purchasing more job bundles.")
      }
      setErrPopup(true)
    }
  }

  return (
    <div>
      <div className='pDashHeaderOuterDiv'>
        <div className="sidebar-icon">
          {window.screen.width < 1024 && <RxHamburgerMenu style={{ color: '#007749', fontSize: '20px' }} onClick={() => { setOpenSidebar(!openSidebar); setDropDownVisible(false) }} />}

        </div>

        <div className='pDashHeaderActionDiv'>
          <img className='pDashHeaderImages' src={feedbackIcon} onClick={() => { setFdbckPopup(true); setDropDownVisible(false) }} />
          <img className='pDashHeaderImages' onClick={() => window.open('https://www.quikhire.ai/uat/faq.php', '_blank')} src={helpIcon} />
          <span className='bellIconDiv'>
            <img className='pDashHeaderImages' onClick={handleNotifications} src={bellIconWithoutDot} />
            {newNotification != 0 ? <span className='notificationCountDiv' onClick={handleNotifications}>{newNotification}</span> : <></>}
          </span>
          <div className='userDetailsDiv' onClick={() => setDropDownVisible(!dropDownVisible)}>
            <img className='pDashHeaderImages' src={profileIcon} />
            <span className='headerUserNameSpan' title={userName}>{userName}</span>
            <img className='dwnArrowUserProfile' src={downArrow} />

            {
              dropDownVisible &&
              <div className='dropDownDiv'>
                <div className='drpDwnItem' onClick={handleProfile}>
                  <img src={userProIcon} className='drpDwnImg' />
                  <span>View Profile</span>
                </div>
                <div className='drpDwnItem' onClick={() => { setLgOutPopup(true); setDropDownVisible(false) }}>
                  <img src={logOutLogo} className='drpDwnImg' />
                  <span>Logout</span>
                </div>
              </div>
            }
          </div>
          {/* <div className='pDashHeadLogoutBtnDiv' onClick={() => setLgOutPopup(true)}>
            <img src={logoutBtn} />
            <span>Logout</span>
          </div> */}
        </div>

      </div>
      <Modal
        isOpen={lgOutPopup}
        onRequestClose={() => setLgOutPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv" style={{ backgroundColor: "#FFF4F4" }}>
            <p onClick={() => setLgOutPopup(false)} className="delAccModalCrossPara" style={{ color: "#BC0000" }}>×</p>
          </div>
        </div>
        <div className='logoutLogoDiv'>
          <img src={LogOutLogo} />
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal", marginBottom: 0 }}>
          Would you like to logout?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
          <button onClick={() => setLgOutPopup(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #007749", color: "#007749", cursor: "pointer" }}>Cancel</button>
          <button style={{ backgroundColor: "#BC0000", cursor: "pointer" }} onClick={handleLogout}>Logout</button>
        </div>
      </Modal>

      <Modal
        isOpen={fdbckPopup}
        onRequestClose={() => { setFdbckPopup(false); setFdbck("") }}
        ariaHideApp={false}
        style={customStyles1}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => { setFdbckPopup(false); setFdbck("") }} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div className='fdbckTitleDiv'>Feedback</div>
        <p className='fdbckPara'>Submit Your Feedback<span style={{ color: "#BC0000" }}>*</span></p>
        <div className='fdbckTxtAreaDiv'>
          <textarea maxLength={256} className='fdbckTxtArea' value={fdbck} placeholder='Write your comments/questions/features requests/issues here.' onChange={(e) => handleInputChange(e)}></textarea>
        </div>
        <div className='fdbckTxtValidator'>
          <p className='fdbckParaNew'>*Feedback should contain atleast 15 characters.</p>
          <span>{fdbck.length}/256</span>
        </div>
        <div className='fdbckButtons'>
          <button className='fdbckBtnOne' onClick={() => { setFdbckPopup(false); setFdbck("") }}>Cancel</button>
          <button className='fdbckBtnTwo' onClick={handleFdbckSend}>Send</button>
        </div>
      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="ApplicantRepodelAccModal1">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              className="ApplicantRepodelAccModalCrossPara"
              onClick={() => { closeFailModal(); setFdbckPopup(true) }}
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="ApplicantRepodelAccModalErrLottie"
          />
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
          <button className="modalOkBtn" onClick={() => { closeFailModal(); setFdbckPopup(true) }}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => { setFdbckPopup(false); setSuccessPopup(false) }}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={openSidebar}
        onRequestClose={() => setOpenSidebar(false)}
        ariaHideApp={false}
        style={sidebarStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModalCrossDiv" style={{ position: "absolute", right: 7, top: 7 }}>
          <p style={{ margin: 0 }} onClick={() => { setOpenSidebar(!openSidebar) }} className="delAccModalCrossPara">×</p>
        </div>
        <div className='sidebarLogoAndTabsDiv'>
          <img src={QuikHireLogo} alt='Something went wrong.' onClick={() => { navigate('/dashboard'); setSubMenu(false); setSubMenuJob(false) }} />
          <div className='pDashNavContainer'>
            <div className='pDashSidebarItems' style={{ backgroundColor: selectedCard == ModuleCards.Dashboard ? "#007749" : "#ffff", color: selectedCard == ModuleCards.Dashboard ? "#ffff" : "#9D9D9D" }} onClick={() => { setSelectedCard(ModuleCards.Dashboard); clearSession(); navigate("/dashboard"); setSubMenu(false); setSubMenuJob(false); setOpenSidebar(!openSidebar) }}>
              <div className="pDashSidebarImgDiv">
                <img src={selectedCard == ModuleCards.Dashboard ? dashIconWhite : dashIconGrey} />
              </div>
              <div className="pDashSidebarNavs">
                Dashboard
              </div>
            </div>
            <div className='pDashSidebarItems' style={{ backgroundColor: selectedCard == ModuleCards.JobRepository ? "#007749" : "#ffff", color: selectedCard == ModuleCards.JobRepository ? "#ffff" : "#9D9D9D" }} onClick={() => {
              setSelectedCard(ModuleCards.JobRepository);
              clearSession();
              navigate("/dashboard/joblist");
              setSubMenu(false);
              setSubMenuJob(false);
              setDropDownVisible(false)
              setOpenSidebar(!openSidebar)
            }}>
              <div className="pDashSidebarImgDiv">
                <img src={selectedCard == ModuleCards.JobRepository ? myJobsWhite : myJobsGray} />
              </div>
              <div className="pDashSidebarNavs">
                My Postings
              </div>
            </div>
            {PortalUserRole == "ROLE_MANAGER" ? <div className='pDashSidebarItems' style={{ backgroundColor: selectedCard == ModuleCards.CompanyJobs ? "#007749" : "#ffff", color: selectedCard == ModuleCards.CompanyJobs ? "#ffff" : "#9D9D9D" }}
              // onClick={()=>{setSelectedCard(ModuleCards.ApplicantRepository );
              // clearSession();
              //   navigate('/dashboard/applicantRepository');
              //   setSubMenu(false);
              //   setSubMenuJob(false);
              //   setDropDownVisible(false)
              //   }}
              onClick={() => {
                clearSession();
                navigate("/dashboard/management/alljobs");
                setSubMenuColorMyJobs(1);
                setSelectedCard(ModuleCards.CompanyJobs);
                setDropDownVisible(false)
                setOpenSidebar(!openSidebar)
              }}
            >
              <div className="pDashSidebarImgDiv">
                <img src={selectedCard == ModuleCards?.CompanyJobs ? companyJobWhite : companyJobGray} />
              </div>
              <div className="pDashSidebarNavs">
                All Jobs
              </div>
            </div> : <></>}
            <div className='pDashSidebarItems' style={{ backgroundColor: selectedCard == ModuleCards.ApplicantRepository ? "#007749" : "#ffff", color: selectedCard == ModuleCards.ApplicantRepository ? "#ffff" : "#9D9D9D" }} onClick={() => {
              setSelectedCard(ModuleCards.ApplicantRepository);
              clearSession();
              navigate('/dashboard/applicantRepository');
              setSubMenu(false);
              setSubMenuJob(false);
              setOpenSidebar(!openSidebar)
            }}>
              <div className="pDashSidebarImgDiv">
                <img src={selectedCard == ModuleCards.ApplicantRepository ? sidebarLogo3 : UserIconGrey} />
              </div>
              <div className="pDashSidebarNavs">
                Applicants
              </div>
            </div>
            {PortalUserRole == "ROLE_MANAGER" ?
              <div className='pDashSidebarItems' style={{ backgroundColor: selectedCard == ModuleCards.Management ? "#007749" : "#ffff", color: selectedCard == ModuleCards.Management ? "#ffff" : "#9D9D9D" }} onClick={() => {
                setSelectedCard(ModuleCards.Management);
                clearSession();
                navigate("/dashboard/management/userManagement");
                setSubMenuColor(2);
                setOpenSidebar(!openSidebar)
                setDropDownVisible(false)
              }}
              >
                <div className="pDashSidebarImgDiv">
                  <img src={selectedCard == ModuleCards.Management ? teamManagementWhite : teamManagementGray} />
                </div>
                <div className="pDashSidebarNavs">
                  Team
                </div>
              </div>
              :
              <></>
            }
            {PortalUserRole == "ROLE_MANAGER" ?
              <div className='pDashSidebarItems' style={{ backgroundColor: selectedCard == ModuleCards.CompanyManagement ? "#007749" : "#ffff", color: selectedCard == ModuleCards.CompanyManagement ? "#ffff" : "#9D9D9D" }} onClick={() => {
                setSelectedCard(ModuleCards.CompanyManagement);
                clearSession();
                setDropDownVisible(false)
                setOpenSidebar(!openSidebar)
                navigate("/dashboard/companyManagement");
              }}
              >
                <div className="pDashSidebarImgDiv">
                  <img src={selectedCard == ModuleCards.CompanyManagement ? companyMngWhite : companyMngGray} />
                </div>
                <div className="pDashSidebarNavs">
                  Companies
                </div>
              </div>
              :
              <></>
            }
            {PortalUserRole == "ROLE_MANAGER" && <div className='pDashSidebarItems' style={{ backgroundColor: selectedCard == ModuleCards.Reports ? "#007749" : "#ffff", color: selectedCard == ModuleCards.Reports ? "#ffff" : "#9D9D9D" }} onClick={() => {
              setSelectedCard(ModuleCards.Reports);
              clearSession(); setSubMenu(false); setSubMenuJob(false); setOpenSidebar(!openSidebar)
              getPremiumMemberUserInfo().then((status) => {
                if (status) {
                  navigate('/dashboard/management/reportdashboard'); setSelectedCard(ModuleCards.Reports)
                }
              })
            }}>
              <div className="pDashSidebarImgDiv">
                <img src={selectedCard == ModuleCards.Reports ? reportsWhite : reportsGray} />
              </div>
              <div className="pDashSidebarNavs">
                Insights
              </div>
            </div>}

            {PortalUserRole == "ROLE_MANAGER"?
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.PurchaseHistory ? "#007749":"#ffff",color:selectedCard==ModuleCards.PurchaseHistory ? "#ffff":"#9D9D9D"}} onClick={()=>{
          setSelectedCard(ModuleCards.PurchaseHistory );
          clearSession();setSubMenu(false); setSubMenuJob(false); setOpenSidebar(!openSidebar)
          setDropDownVisible(false)
          navigate("/dashboard/purchaseHistory");
        }}
          >
            <div className="pDashSidebarImgDiv">
              <img src={selectedCard==ModuleCards.PurchaseHistory ? companyMngWhite:companyMngGray} />
            </div>
            <div className="pDashSidebarNavs">
            Purchase History
            </div>
          </div>
         :
         <></>
        }

          </div>
        </div>
        <div style={{ position: "absolute", bottom: "20px", width: "100%" }}>
          <div className='pDashSidebarBottomDiv'>
            {/* <img src={createJob} className="pDashSidebarCreateJobs" alt='Something went wrong' onClick={()=>{
           getPremiumMemberUserInfo().then((status) => {
            if(status){
              setSelectedCard(2);
              navigate('/dashboard/createJobs');
              setOpenSidebar(!openSidebar)
            }})
         }}/> */}
            <div className="pDashSidebarCreateJobs" id='createJob' onClick={handleCreateJob}>
              <img src={qIcon} />Create a job
            </div>
            {/* <div className='pDashSidebarCopyright' style={{border:"1px solid red"}}> */}
            <div className='pDashSidebarCopyright' style={{ textAlign: "center" }}>&copy; {new Date().getFullYear()} QuikHire.ai</div>
            <div className='pDashSidebarCopyright' style={{ textAlign: "center" }}>All rights reserved.</div>
            {/* </div> */}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={errPopup}
        onRequestClose={() => setErrPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setErrPopup(false)}>OK</button>
        </div>
      </Modal>
    </div>
  )
}

export default DashboardHeader