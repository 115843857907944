import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import '../../components/LicenseModal/LicenseModal.css'
import RazorpayCheckout from '../../screens/Payment/RazorpayCheckout';
import { Url } from '../../EnvoirnmentSetup/APIs';
const LicenseModal = ({openModal,closeModal}) => {
    const [bundlePrice,setBundlePrice] = useState(0)
    const [userLicensePrice,setUserLicensePrice] = useState(0)
    const [companyLicensePrice,setCompanyLicensePrice] = useState(0)
    const [AmountEncriptionKey,setAmountEncriptionKey] = useState('')

    const [purchaseItems,setPurchaseItems] = useState({
      fiveJobsBundle : 0,
      userLicense : 0,
      companyLincense : 0
    })

    let TotalJobBundleAmount = purchaseItems?.fiveJobsBundle * bundlePrice;
    let TotalUserLicenceAmount = purchaseItems?.userLicense * userLicensePrice;
    let TotalCompanyLicenceAmount = purchaseItems?.companyLincense * companyLicensePrice;


    const licenseModalStyle = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width:window.screen.width>1023 ? "40%" : "80%",
          borderRadius: "25px",
          padding: "10px",
          height:"460px"
        },
      };

      useEffect(()=>{
        GetPricingData()
      },[])

      const GetPricingData = async () => {
        let token = sessionStorage.getItem("token");

        fetch(Url?.getPricing, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: "Bearer " + token,
          },
       
        }).then((response) => response.json())
          .then((res) => {
            console.log("llllllllll",res)
            if (res.SUCCESS) {
             setAmountEncriptionKey(res.TOKEN)
             res.DATA.map((i)=>{
                if(i?.licenseType=='USER' && i?.status==true){
                  setUserLicensePrice(i?.price)
                }else  if(i?.licenseType=='COMPANY' && i?.status==true){
                  setCompanyLicensePrice(i?.price)
                }else  if(i?.licenseType=='JOB_BUNDLE' && i?.status==true){
                  setBundlePrice(i?.price)
                }
             })
    
            }
          })
          .catch((err) => {
            console.error("errors", err);
          
          })
      };



      const handleDecreaseCount = (field,count) => {
        if(count>0){   
                setPurchaseItems({...purchaseItems,[field]:count - 1})
        }
      }
      const handleIncreaseCount = (field,count) => {
        if(count<20){
            setPurchaseItems({...purchaseItems,[field]:count + 1})
        }
      }
      const handleCloseModal = () => {
        setPurchaseItems({fiveJobsBundle:0,userLicense:0,companyLincense:0})
        closeModal(false)
      }
      const handleInputChange = (e,field) => {
        let value = e?.target?.value
        if(value<21 && value?.length<4){
          setPurchaseItems({...purchaseItems,[field]:value})
        }
      }
  return (
    <div>
        <Modal
        isOpen={openModal}
        onRequestClose={()=>closeModal(false)}
        ariaHideApp={false}
        style={licenseModalStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      > 
        <div className="CmpnyDetailsCrossIcon" onClick={()=>handleCloseModal()}>x</div>
        <div className="CmpnyDetailTitle">Purchase License</div>
        <div className='licenseContentOuterDiv'>
        <div className='licenseContent'>
            <div><span>5 Jobs Bundle <sub style={{fontSize:"0.6rem",color:"gray"}}> ( per ₹{bundlePrice} )</sub> </span><span>:</span></div>
            <div>
                <span onClick={()=>handleDecreaseCount("fiveJobsBundle",purchaseItems?.fiveJobsBundle)}>-</span>
                <input readOnly value={purchaseItems?.fiveJobsBundle} onChange={(e)=>handleInputChange(e,"fiveJobsBundle")}/>
                <span onClick={()=>handleIncreaseCount("fiveJobsBundle",purchaseItems?.fiveJobsBundle)}>+</span>
            </div>
        </div>
        <div className='licenseContent'>
            <div><span>User License <sub style={{fontSize:"0.6rem",color:"gray"}}> ( per ₹{userLicensePrice} )</sub> </span><span>:</span></div>
            <div>
                <span onClick={()=>handleDecreaseCount("userLicense",purchaseItems?.userLicense)}>-</span>
                <input readOnly value={purchaseItems?.userLicense} onChange={(e)=>handleInputChange(e,"userLicense")}/>
                <span onClick={()=>handleIncreaseCount("userLicense",purchaseItems?.userLicense)}>+</span>
            </div>
        </div>
        <div className='licenseContent'>
            <div><span>Company License <sub style={{fontSize:"0.6rem",color:"gray"}}> ( per ₹{companyLicensePrice} )</sub> </span><span>:</span></div>
            <div>
                <span onClick={()=>handleDecreaseCount("companyLincense",purchaseItems?.companyLincense)}>-</span>
                <input readOnly value={purchaseItems?.companyLincense} onChange={(e)=>handleInputChange(e,"companyLincense")}/>
                <span onClick={()=>handleIncreaseCount("companyLincense",purchaseItems?.companyLincense)}>+</span>
            </div>
        </div>
        </div>
        <div className='totalOuterDiv'>
          <div className='totalContent'>
            <span><span>{purchaseItems?.fiveJobsBundle} X 5 Jobs Bundle</span><span>=</span></span>
            <span>₹{purchaseItems?.fiveJobsBundle * bundlePrice}</span>
          </div>
          <div className='totalContent'>
            <span><span>{purchaseItems?.userLicense} X User License</span><span>=</span></span>
            <span>₹{purchaseItems?.userLicense * userLicensePrice}</span>
          </div>
          <div className='totalContent'>
            <span><span>{purchaseItems?.companyLincense} X Company License</span><span>=</span></span>
            <span>₹{purchaseItems?.companyLincense * companyLicensePrice}</span>
          </div>
        </div>
        <div className='TotalPayableAmount gstDiv'>
          <span>Total Amount</span>
          <span>₹{
          ((TotalJobBundleAmount + TotalUserLicenceAmount + TotalCompanyLicenceAmount)).toFixed(2)
          } </span>
        </div>
        <div className='gstDiv'>
          <span>+ 18% GST</span>
        </div>
        <div className='TotalPayableAmount gstDiv'>
          <span>Total Payable Amount</span>
          <span>₹{
          ((TotalJobBundleAmount + TotalUserLicenceAmount + TotalCompanyLicenceAmount) + 
          ((TotalJobBundleAmount + TotalUserLicenceAmount + TotalCompanyLicenceAmount) * 0.18)).toFixed(2)
          } </span>
        </div>
        <div style={{display:"flex", justifyContent:"center", margin:"10px"}}>
         <RazorpayCheckout
          // ButtonName={"Proceed To Pay"}
          closeModal={handleCloseModal}
          totalAmount={
            ((TotalJobBundleAmount + TotalUserLicenceAmount + TotalCompanyLicenceAmount) + 
            ((TotalJobBundleAmount + TotalUserLicenceAmount + TotalCompanyLicenceAmount) * 0.18)).toFixed(2)
          }
          amountEncryptionKey={AmountEncriptionKey}
          allPurchaseValues = {{...purchaseItems, TotalJobBundleAmount, TotalUserLicenceAmount, TotalCompanyLicenceAmount}}
         />
        </div>
      </Modal>
    </div>
  )
}

export default LicenseModal